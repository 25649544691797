import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';

import { DashboardFilters, DashboardType } from './types';

const today = moment().format('YYYY-MM-DD');
const sixMonthsAgo = moment().subtract(6, 'months').format('YYYY-MM-DD');

export const initialState: DashboardType = {
	id: '',
	name: '',
	active: false,
	createdAt: '',
	items: [],
	metaData: {
		dashboardFilter: {
			assignments: [],
			assignmentTypes: [],
			brands: [],
			campaigns: [],
			clients: [],
			influencers: [],
			countries: [],
			from: sixMonthsAgo,
			to: today,
			uploads: [],
		},
		summaryItems: [],
	},
	users: [],
	dashboardUsers: [],
	links: {},
	paidMedia: [],
	hasPaidMedia: false,
	uniqueInfluencers: 0,
};

const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {
		setDashboard(_state, action: PayloadAction<DashboardType>) {
			return action.payload;
		},

		setFilterOnDashboard(state, action: PayloadAction<DashboardFilters>) {
			return {
				...state,
				metaData: {
					...state.metaData,
					dashboardFilter: action.payload,
				},
			};
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		setSummaryItemsOnDashboard(state, action: PayloadAction<any[]>) {
			return {
				...state,
				metaData: {
					...state.metaData,
					summaryItems: action.payload,
				},
			};
		},

		clearDashboard(state) {
			state = initialState;
			return state;
		},
	},
});

export const { setDashboard, clearDashboard, setFilterOnDashboard, setSummaryItemsOnDashboard } = dashboardSlice.actions;
export default dashboardSlice.reducer;
